window.addEventListener('load', () => {
  if (document.querySelector('.header')) {
    const menuBtn = document.querySelector('.header__burger')
    const menu = document.querySelector('.header__menu')
    const menuLinks = menu.querySelectorAll('.header__menu-link')
    const menuLogin = menu.querySelector('.header__menu-login')

    function toggleMenu() {
      if (!menu.classList.contains('active')) {
        menu.classList.add('active')
        menuBtn.classList.add('active')
        document.body.classList.add('body_fix')
      } else {
        menu.classList.remove('active')
        menuBtn.classList.remove('active')
        document.body.classList.remove('body_fix')
      }
    }

    menuBtn.addEventListener('click', toggleMenu)
    menuLogin.addEventListener('click', toggleMenu)
    menuLinks.forEach(el => el.addEventListener('click', toggleMenu))
  }
})
